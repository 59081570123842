body {
    height: 100vh;
    margin: 0;
}

#root {
    height: 100%;
}

.leaflet-attribution-flag {
    width: 0 !important;
}
